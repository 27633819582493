import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 72vw;
  margin: 0 2vw;
  // border: 10px solid BLACK;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4vw 0 0;
  align-items: center;
  margin: 2vh 0;
  // border: 10px solid BLACK;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 1vw;
  // border: 10px solid BLACK;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1vw;
  // border: 10px solid BLACK;
`;

export const ClassInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw 0 0;
  margin: 0 0 2vh 0;
  // border: 10px solid BLACK;
`;

export const LiveScreenContainer = styled.div`
  width: 80%;
  border: 10px solid GREEN;
`;
