import styled from 'styled-components';

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2vh 0;
`;

export const ImageButtonContainer = styled.div`
  height: 16vh;
  width: 30vw;
  display: flex;
  margin: 1.6vw;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // border: 10px solid red;
`;
