import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1vh 0;
  gap: 2vh;
  //   border: 10px solid black;
`;
