import styled from 'styled-components';

export const ContentInner = styled.div`
  width: 100%;
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentBigText = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 1.3vw;
  font-weight: 600;
`;

export const ContentSmallText = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 0.9vw;
  font-weight: 400;
  color: #767572;
`;
