export const COLORS = {
  NAVY: '#012140',
  YELLOW: '#FFEA75',
  GREEN: '#79DEB4',
  ORANGE: '#FF785B',
  SKYBLUE: '#E7FAFE',
  TAG: '#D9D9D9',
  WHITE: '#FFFFFF',
  GRAY: '#585858',
  BLACK: '#000000',
  LIGHTGRAY: '#ADADAD',
  GRAPEFRUIT: 'rgba(255, 89, 53, 0.4)',
  DARKGRAPEFRUIT: '#FF785B',
  RED: '#FF0000',
  LIGHTORANGE: '#FFE7E2',
  STROKE: '#CECECE',
  SUCCESS: '#79DEB4',
  SALES: '#FFD800',
  LIGHTBLUE: '#4A5568',
  한식: '#C93A40',
  일식: '#2E3A59',
  양식: '#4A7A8C',
  중식: '#D9434E',
  건강: '#7BB661',
  간편: '#A2A3A5',
  단짠: '#F5C04E',
  매운: '#E84A27',
  비건: '#609F60',
  야식: '#373B41',
  브런치: '#E2C58D',
  간식: '#F6A532',
  초보: '#7FBCD2',
  가벼운: '#F2D0A9',
};

export const TAGS = {
  1: '한식',
  2: '중식',
  3: '일식',
  4: '양식',
  5: '건강',
  6: '간편',
  7: '단짠',
  8: '매운',
  9: '비건',
  10: '야식',
  11: '브런치',
  12: '간식',
  13: '초보',
  14: '가벼운',
};

export const INGREDIENTS = {
  크림치즈: '6300',
  설탕: '3180',
  알룰로스: '8400',
  옥수수전분: '10000',
  계란: '8700',
  생크림: '6500',
  레몬즙: '2800',
  불린미역: '10000',
  다진마늘: '7980',
  국간장: '7900',
  소금: '8900',
  새우: '14000',
  물: '12960',
  참치액젓: '6980',
};

export const TAG_VALUES = Object.values(TAGS);

export const TAG_COLOR_MAPPING = Object.fromEntries(
  Object.entries(TAGS).map(([key, tagValue]) => [tagValue, COLORS[tagValue]]),
);
